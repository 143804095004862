import { AppBar, Box, Button, Grid, Link, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { BrandLogoIcon } from "components/ui-kit/icons/iconComponents/BrandLogoIcon";
import { DownArrowWhiteIcon } from "components/ui-kit/icons/iconComponents/DownArrowWhiteIcon";
import { MenuIconIcon } from "components/ui-kit/icons/iconComponents/MenuIconIcon";
import { MobBrandLogoIcon } from "components/ui-kit/icons/iconComponents/MobBrandLogoIcon";
import { SidebarArrowIcon } from "components/ui-kit/icons/iconComponents/SidebarArrowIcon";
import { EnIcon } from "components/ui-kit/icons/iconComponents/EnIcon";
import {
  ROUTE_PATHS,
  MY_ACCOUNT_DROPDOWN,
  REPORTS_DROPDOWN,
  LANGUAGES,
  LANGUAGE,
} from "constants/index";
import { getAuthToken, removeAuthToken } from "helpers/cookie.helpers";
import useStyles from "./Header.styles";
import SearchGame from "pages/Casino/components/SearchGame/index";
import { format } from "date-fns";
import useUserWalletNamespace from "socket-resources/hooks/useDemoSocketNameSpace";
import { useDispatch, useSelector } from "react-redux";
import {
  handelLogoutDialog,
  handleBalanceChange,
  handleLanguageChange,
  handleLoyaltyDetalsChange,
} from "redux-thunk/redux/user/user.slice";
import { useTranslation } from "react-i18next";
import { EsIcon } from "components/ui-kit/icons/iconComponents/EsIcon";
import { PtIcon } from "components/ui-kit/icons/iconComponents/PtIcon";
import HeaderLinks from "./components/HeaderLinks";

import { headerLinks } from "utils/common";
import defaultProfileImg from "../../../assets/images/usericon.svg";
import { DialogBox } from "../Popup/Dialog/DialogBox";
import ReferAFriend from "./components/ReferAFriend";
import {
  fetchGameCategoryThunk,
  fetchGameProvidersThunk,
  fetchGameSubCategoryThunk,
} from "redux-thunk/thunk/casino/casino.thunk";
import { isMobile } from "react-device-detect";
import { colors } from "theme/colors/index";
import { updateLanguageThunk } from "redux-thunk/thunk/user/user.thunk";
import useLoyaltyNamespace from "socket-resources/hooks/useLoyaltyNameSpace";

import FormatNumber from "utils/formatNumber";
import { fetchBannerThunk } from "redux-thunk/thunk/casino/casino.thunk";
import { StyledTooltip } from "components/ui-kit/Tooltip/styles";

import ReplayIcon from '@mui/icons-material/Replay';
import { fetchUserInformationThunk } from 'redux-thunk/thunk/user/user.thunk'
import InSufficiantBalDialog from "components/ui-kit/Dialog/InSufficiantBalDialog/index";
import { OnlybetsPluginRef } from "helpers/player.helpers";
import { usePlayerPlugin } from "hooks/usePlayerPlugin";
import NotSigninDialog from "components/ui-kit/Dialog/NotSigninDialog/index";
import { useSiteSettings } from "hooks/useSettings";

const DepositDropDown = () => {
  const ref = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const { t } = useTranslation();
  const { userInformationData } = useSelector((state) => state.user);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeRoute = (pathName) => {
    navigate(pathName);
  };

  useEffect(() => {
    if (window['OnlybetsPluginRef']) {
      window['OnlybetsPluginRef'].setUser(userInformationData);
    }
  }, [userInformationData]);

  return (
    <Grid className={classes.amountDropdown} ref={ref} >
      <Grid className="custom-dropdown">
        <StyledTooltip
          placement={'top'}
          enterDelay={300}
          enterNextDelay={300}
          title={t('viewBalanceInformation')}
        >
          <Grid
            id="basic-button-1"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            // onMouseEnter={handleClick}
            className="amountSection"
          >
            {/* {userInformationData?.userWallet?.currencyCode}   */}
            {FormatNumber.currency(userInformationData?.userWallet?.totalAmount ?? 0)}
            {/* <DownArrowWhiteIcon /> */}
          </Grid>
        </StyledTooltip>
        <Menu
          id="basic-menu-1"
          anchorEl={ref.current}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: 45,
            horizontal: -15,
          }}
          className={`${classes.dropdownElementReport} wallet-dropdown`}
        >
          <div className="drowpdown-header">
            <Typography><strong>{t("wallet")}</strong></Typography>
            <StyledTooltip
              enterDelay={300}
              enterNextDelay={300}
              title={t('update')}
            >
              <ReplayIcon onClick={() => dispatch(fetchUserInformationThunk())} />
            </StyledTooltip>
          </div>
          <tbody>
            <tr>
              <td className="label"><Typography>{t("balance")}</Typography></td>
              <td className="value"><Typography><strong>{FormatNumber.currency(userInformationData?.userWallet?.amount ?? 0)}</strong></Typography></td>
            </tr>
            <tr>
              <td className="label muted"><Typography>{t("bonus")}</Typography></td>
              <td className="value"><Typography>{FormatNumber.currency(userInformationData?.userWallet?.nonCashAmount ?? 0)}</Typography></td>
            </tr>
          </tbody>
        </Menu>
        <Button
          className={`${classes?.btnSecondary} ${classes.desktopDeposit}`}
          onClick={() => changeRoute(ROUTE_PATHS.deposit)}
        >
          {t("deposit")}
        </Button>
      </Grid>
    </Grid>
  );
};

const GetIcon = ({ languageCode }) => {
  switch (languageCode) {
    case "en":
      return <EnIcon />;
    case "es":
      return <EsIcon />;
    case "pt":
      return <PtIcon />;
    default:
      return <EnIcon />;
  }
};

const MyAccountDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDialogClickOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectTo = (menu) => {
    navigate(menu?.path);
    handleClose();
  };

  const handleLogout = () => {
    dispatch(handelLogoutDialog({ show: true }));
  };

  const { t } = useTranslation();

  return (
    <>
      <Grid className="custom-dropdown">
        <Button
          id="basic-button-1"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {t("myAccount")}
          <DownArrowWhiteIcon />
        </Button>
        <Menu
          id="basic-menu-1"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className={classes.dropdownElementReport}
        >
          {Array.isArray(MY_ACCOUNT_DROPDOWN) &&
            MY_ACCOUNT_DROPDOWN.map((menu) => menu ? (
              <MenuItem
                divider
                key={menu?.id}
                className={`${pathname === menu?.path ? "active" : ""}`}
                onClick={() => redirectTo(menu)}
              >
                {menu.translationLabel ? t(menu.translationLabel) : menu.label}
              </MenuItem>
            ) : null)}
          {/* <MenuItem onClick={handleDialogClickOpen}>
            {t("referAFriend")}
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
        </Menu>
      </Grid>

      {openDialog && (
        <DialogBox
          handleClose={handleDialogClose}
          title={t("referAFriend")}
          open={open}
          renderComponent={<ReferAFriend title={t("referAFriend")} />}
        />
      )}
    </>
  );
};

const ReportsDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectTo = (menu) => {
    navigate(menu?.path);
    handleClose();
  };

  const { t } = useTranslation();

  return (
    <Grid className="custom-dropdown">
      <Button
        id="basic-button-2"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {t("reports")} <DownArrowWhiteIcon />
      </Button>
      <Menu
        id="basic-menu-2"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className={classes.dropdownElementProfile}
      >
        {Array.isArray(REPORTS_DROPDOWN) &&
          REPORTS_DROPDOWN.map((menu) => (
            <MenuItem
              key={menu?.id}
              className={`${pathname === menu?.path ? "active" : ""}`}
              onClick={() => redirectTo(menu)}
            >
              {menu.translationLabel ? t(menu.translationLabel) : menu.label}
            </MenuItem>
          ))}
      </Menu>
    </Grid>
  );
};

const AccountReportsDropdown = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userInformationData } = useSelector((state) => state.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectTo = (menu) => {
    navigate(menu?.path);
    handleClose();
  };
  const handleLogout = () => {
    removeAuthToken();
    handleClose();
    navigate(ROUTE_PATHS.home, { replace: true });
  };

  const { t } = useTranslation();

  return (
    <Grid className="custom-dropdown">
      <Grid
        className="userIcon"
        id="basic-button-2"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img
          src={userInformationData?.profileImage || defaultProfileImg}
          alt="user Icon"
        />
      </Grid>

      <Menu
        id="basic-menu-2"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className={classes.dropdownElementProfile}
      >
        <Grid>
          <Grid className={classes.headerUsername}>
            <Typography>
              {t("hello")} &nbsp;{userInformationData?.username}
            </Typography>
          </Grid>
          <Grid>
            <Typography>{t("loyality")}</Typography>
            <MenuItem onClick={handleClose}>
              {t("loyaltyPoints", {
                number: userInformationData?.loyaltyPoints || 0,
              })}
            </MenuItem>
            <MenuItem onClick={handleClose}>
              {t("loyaltyCoins", {
                number: userInformationData?.loyaltyCoins || 0,
              })}
            </MenuItem>
          </Grid>
          <Grid>
            <Typography>{t("myAccount")}</Typography>
            {Array.isArray(MY_ACCOUNT_DROPDOWN) &&
              MY_ACCOUNT_DROPDOWN.map((menu) => (
                <MenuItem
                  key={menu?.id}
                  className={`${pathname === menu?.path ? "active" : ""}`}
                  onClick={() => redirectTo(menu)}
                >
                  {t(menu?.translationLabel) || menu.label}
                </MenuItem>
              ))}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Grid>
          <Grid>
            <Typography>{t("reports")}</Typography>
            {Array.isArray(REPORTS_DROPDOWN) &&
              REPORTS_DROPDOWN.map((menu) => (
                <MenuItem
                  key={menu?.id}
                  className={`${pathname === menu?.path ? "active" : ""}`}
                  onClick={() => redirectTo(menu)}
                >
                  {t(menu.translationLabel) || menu.label}
                </MenuItem>
              ))}
          </Grid>
        </Grid>
      </Menu>
    </Grid>
  );
};

const CustomeDropDown = () => {
  const classes = useStyles();
  // const { pathname } = useLocation()
  const dispatch = useDispatch();
  const { language, userInformationData } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [languageTitle, setLanguageTitle] = useState((state) => {
    const defaultLanguage = LANGUAGES.find(
      (lang) => lang.languageCode === state
    ) || { label: LANGUAGES[0].languageCode };
    return defaultLanguage.label.toUpperCase();
  });
  useEffect(() => {
    setLanguageTitle(language?.toUpperCase());
  }, [language]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelMenuItemClick = (menu) => {
    const payload = {
      locale: menu.languageCode,
    };
    if (window.bt) {
      window.bt.kill()
    }

    if (getAuthToken()) {
      dispatch(updateLanguageThunk(payload));
    } else {
      dispatch(handleLanguageChange(menu?.languageCode));
    }

    handleClose();
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (
      getAuthToken() &&
      language !== userInformationData?.locale?.toLowerCase() &&
      userInformationData?.locale?.toLowerCase()
    ) {
      dispatch(
        updateLanguageThunk({
          locale: userInformationData?.locale?.toLowerCase(),
        })
      );
    }
  }, [userInformationData]);

  return (
    <Grid className="custom-dropdown">
      <Button
        id="basic-button-2"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Box className={classes?.languageIconBox}>
          <GetIcon languageCode={language?.toLowerCase()} />
        </Box>
        <span className={classes.dropdownLanguageTitle}> {languageTitle} </span>
        <DownArrowWhiteIcon />
      </Button>
      <Menu
        id="basic-menu-2"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className={classes.dropdownElementProfile}
      >
        {Array.isArray(LANGUAGES) &&
          LANGUAGES.map((menu) => (
            <MenuItem
              key={menu?.languageCode}
              className={`${language === menu?.languageCode ? "active" : ""}`}
              onClick={() => handelMenuItemClick(menu)}
            >
              <GetIcon languageCode={menu.languageCode} />
              <span style={{ marginLeft: "5px", color: colors.white }}>
                {menu.languageCode.toUpperCase()}
              </span>
            </MenuItem>
          ))}
      </Menu>
    </Grid>
  );
};

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { listenBalanceUpdateEvent } = useUserWalletNamespace();
  const { listenLoyaltyDetailsEvent } = useLoyaltyNamespace();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [modifiedPathname, setModifiedPathname] = useState("/");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { pathname } = useLocation();
  const { userInformationData } = useSelector((state) => state.user);
  const [isMobSidebarOpen, setIsMobSidebarOpen] = useState(false);
  const { gameCategories, bannerListData } = useSelector((state) => state.casino);
  const { showHeader } = useSelector((state) => state.common);
  const navigation = useNavigate();
  const settings = useSiteSettings();

  const NotSigninDialogRef = useRef(null);
  const InSufficiantBalDialogRef = useRef(null);
  const playerPlugin = usePlayerPlugin();

  const handleCloseMobSidebar = () => {
    setIsMobSidebarOpen(false);
  };
  const handleOpenMobSidebar = () => {
    setIsMobSidebarOpen(!isMobSidebarOpen);
  };

  const getHeaderLinks = useMemo(() => {
    return headerLinks(t, settings, ['loyalty']);
  }, [headerLinks, settings]);

  useEffect(() => {
    if ([ROUTE_PATHS.casino, ROUTE_PATHS.casino2].includes(pathname)) {
      setModifiedPathname("casino");
    } else if (pathname === ROUTE_PATHS.liveCasino) {
      setModifiedPathname("live-casino");
    }
  }, [pathname]);

  const casinoGameCategoryId = useMemo(() => {
    const result = gameCategories?.filter((category) => {
      return (
        modifiedPathname?.toUpperCase() ===
        JSON.parse(category?.name)?.["EN"?.toUpperCase()]?.toUpperCase()
      );
    });
    return result?.[0]?.gameCategoryId;
  }, [gameCategories]);

  useEffect(() => {
    if (casinoGameCategoryId) {
      const payload = { categoryId: casinoGameCategoryId, isMobile: isMobile };
      dispatch(fetchGameSubCategoryThunk({ payload }));
    }
  }, [casinoGameCategoryId]);

  useEffect(() => {
    listenBalanceUpdateEvent((res) => {
      if (res) {
        dispatch(handleBalanceChange(res?.data));
      }
    });
    listenLoyaltyDetailsEvent((res) => {
      if (res) {
        dispatch(handleLoyaltyDetalsChange(res?.data?.balance));
      }
    });
  }, []);

  useEffect(() => {
    const payload = { isMobile };
    dispatch(fetchGameProvidersThunk(payload));
    dispatch(fetchGameCategoryThunk({}));
    setIsLoggedIn(!!getAuthToken());
  }, []);

  const changeRoute = (pathName) => {
    navigate(pathName);
  };

  const signupButtonClick = (route) => {
    const bonusCode = bannerListData?.availableJoiningBonus?.bonusCode;
    navigate(`${ROUTE_PATHS?.register}${bonusCode ? `?bonusCode=${bonusCode}` : ''}`);
  };

  useEffect(() => {
    if (!bannerListData) dispatch(fetchBannerThunk({}));
  }, [])

  useEffect(() => {
    playerPlugin().interceptor['click:home:on'] = async () => {
      const pathname = window.location.pathname;
      if (!/casino|live-casino|influencers/.test(pathname)) {
        navigation(ROUTE_PATHS.influencers)
        return false;
      } else if (await InSufficiantBalDialogRef?.current?.validate("live")) {
        return false
      } else return true;
    }

    playerPlugin().on("demo:timeout:finished", () => {
      if (InSufficiantBalDialogRef && InSufficiantBalDialogRef.current) {
        InSufficiantBalDialogRef.current.open();
      }
    })

    playerPlugin().on("click:deposit", () => {
      NotSigninDialogRef?.current?.validate(() => {
        navigate(ROUTE_PATHS.deposit)
      })
    })

  }, [userInformationData]);

  return (
    <header className={classes.HeaderWrapper}>
      <AppBar as={"div"} position={"static"} className={showHeader ? classes.header : classes.displayHeader}>
        {/* first/top header */}
        <Grid className={classes.headerTopContent}>
          <Grid className={classes.mobHeaderLeft}>
            <Link
              href="javascript:void(0);"
              className={classes.mobMenuIcon}
              onClick={handleOpenMobSidebar}
              style={{ display: "flex" }}
            >
              <MenuIconIcon />
            </Link>
            <Link href={ROUTE_PATHS.casino} style={{ display: "flex" }}>
              <MobBrandLogoIcon />
            </Link>
            <Grid
              id="mobSidebar"
              className={`${classes.mobSidebar} ${isMobSidebarOpen ? classes.showMobSidebar : ""
                }`}
            >
              <Grid
                className={classes.sidebarCollapseIcon}
                onClick={handleCloseMobSidebar}
              >
                <Link href="javascript:void(0);">
                  <SidebarArrowIcon />
                </Link>
              </Grid>
              <HeaderLinks
                renderLinksData={getHeaderLinks}
                className={classes.mobSidebarMenuList}
              />
            </Grid>
          </Grid>

          <Grid className={classes.headerLeft}>
            <Grid className={classes.brandLogo}>
              <NavLink to={ROUTE_PATHS.casino}>
                <BrandLogoIcon />
              </NavLink>
            </Grid>
            <HeaderLinks
              renderLinksData={getHeaderLinks}
              className={classes.menuWrap}
            />
          </Grid>

          <Grid className={classes.headerRight}>
            {isLoggedIn && (
              <Button
                className={`${classes?.btnSecondary} ${classes.mobDeposit}`}
                onClick={() => changeRoute(ROUTE_PATHS.deposit)}
              >
                {t("deposit")}
              </Button>
            )}
            <Grid className="web-search">
              <SearchGame />
            </Grid>
            {isLoggedIn &&
              !(
                pathname === ROUTE_PATHS.login ||
                pathname === ROUTE_PATHS.register
              ) ? (
              <>
                <Button
                  className={`${classes.btnSecondary} ${classes.depositBtn}`}
                  onClick={() => navigate(ROUTE_PATHS.deposit)}
                  sx={{
                    margin: "0 0.625rem",
                  }}
                >
                  {t("deposit")}
                </Button>
                <Grid>
                  <DepositDropDown />
                </Grid>
                <Grid className={classes.userIcon}>
                  <Grid className="custom-dropdown">
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    //onClick={handleClick}
                    >
                      <img
                        src={
                          userInformationData?.profileImage || defaultProfileImg
                        }
                        alt="user Icon"
                      />
                      {/* <Typography className="user-count">
                        {userInformationData?.level} <DownArrowWhiteIcon />
                      </Typography> */}
                    </Button>
                  </Grid>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    className={classes.dropdownElementProfile}
                  >
                    <MenuItem onClick={handleClose}>
                      {t("loyaltyPoints", {
                        number: userInformationData?.loyaltyPoints || 0,
                      })}
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      {t("loyaltyCoins", {
                        number: userInformationData?.loyaltyCoins || 0,
                      })}
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid
                  sx={{
                    display: { sm: "block", md: "none" },
                  }}
                >
                  <AccountReportsDropdown />
                </Grid>

                <Grid className="userDetail">
                  <Typography>{userInformationData?.username}</Typography>
                </Grid>
              </>
            ) : (
              <>
                <Button
                  className={classes.btnPrimary}
                  onClick={() => navigate(ROUTE_PATHS.login)}
                >
                  {t("login")}
                </Button>
                <Button
                  className={classes.btnSecondary}
                  onClick={signupButtonClick}
                >
                  {t("signup")}
                </Button>
                <Grid className={classes.logoSection} pl={"10px"}>
                  <Grid className={classes.languageWrap}>
                    <CustomeDropDown />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {/* second header */}
        {isLoggedIn &&
          !(
            pathname === ROUTE_PATHS.login || pathname === ROUTE_PATHS.register
          ) && (
            <Grid className={classes.subHeaderWrapp}>
              <Grid className={classes.subHeader}>
                <Typography />
                <Grid className="sub-header-right">
                  <Typography className="sub-header-web-date">
                    {t("lastLoginTime")} &nbsp;
                    {userInformationData?.lastLoginDate &&
                      format(
                        new Date(userInformationData?.lastLoginDate),
                        "dd/MM/yyyy HH:mm"
                      )}
                  </Typography>
                  <MyAccountDropdown />
                  <ReportsDropdown />
                  <Grid className={classes.logoSection}>
                    <Grid className={classes.languageWrap}>
                      <CustomeDropDown />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
      </AppBar>
      <InSufficiantBalDialog ref={InSufficiantBalDialogRef} />
      <NotSigninDialog ref={NotSigninDialogRef} />
    </header>
  );
};

export default Header;
